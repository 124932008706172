const config = {
    //  "curriculaserver": "http://10.7.41.240:5002",
    //   "curriculaserver": "http://192.168.0.154:5002",
    // "curriculaserver": "https://dld.srmrmp.edu.in/rmpetecurricula2021/server",
    "curriculaserver": "https://ewbc.org/server",
    "key": "john",
    "build": "V5",
    "batchYear": "",
    "totalYear": "",
    "toolShortname": "Integrated Talent Management Systems (ITMS)",
    "toolname": "Swaziland Campus",
    "toolSubName": "ITMS",
    "studentIDdigits": 12,
    "studentProfile": 0,   //Student Profile View : 1 - ON,  0 - OFF
    "SEMESTER_START": 2021,
    "toolfullname": "ITMS",
    "institutionName": "EWBC",
    // "offerDeptName": "Emmanuel Wesleyan Bible College",
    "offerDeptName": "EMMANUEL WESLEYAN BIBLE COLLEGE",
    "institutionShortName": "ITMS",
    "collegeName": "ITMS",
   "CLAfullName": "Assessment Test / Field Visit ",
    "CLAshortName": "AT / LAB",
    "UnitTestName": "Internal Assessment",
    "ReduceFeaturesFromCoordinatorAndFaculty": 1, // 1 - ON, 0 - OFF
    "signupmsg": "For your Login Credentials, Kindly Contact Treinova at https://dreamitdoit.tech",
    "footer1": "v4.0 © 2023",
    "footer2": " Treinova",
    "mathJaxLib": 'https://dld.srmist.edu.in/apps/editor/MathJax.js?config=TeX-AMS-MML_HTMLorMML',
    "supportMailID": "info@treinova.com",
    "about": "This ITMS is built with innovative features designed to streamline administrative processes, enhance academic management, and foster a thriving learning environment. This platform integrates student information, course management, faculty administration, and financial operations in one user-friendly system, enabling the management to focus more on nurturing spiritual growth and less on paperwork. ​Experience the difference today with a solution built specifically for your needs!",
    "about1": "​Experience the difference today with a solution built specifically for your needs!",

    "departments": [
        "ALL",
       "Department of Biblical Studies",
        "Department of Theology and Doctrine",
        "Department of Church History",
        "Department of Ministry and Leadership",
        "Department of Christian Education",
        "Department of Missions and Evangelism",
        "Department of Ethics and Christian Living",
        "Department of Practical Theology",
        "Department of Interdisciplinary Studies",
        "Department of Languages and Humanities"
    ],
    "courseCategory": [
        "Biblical Studies​",
        "Theology and Doctrine​",
        "Church History​",
        "Ministry and Leadership​",
        "Christian Education​",
        "Mission and Evangelism​",
        "Ethics and Christian Living​",
        "Interdisciplinary Studies​",
        "Practical Ministry Training​",
        "Languages and Humanities​",
        "Specialized Studies​",
        "Others"
    ],
    "apps": [
        // { label: "4-Function Calculator", value: "4-Function Calculator", link: "https://dld.srmist.edu.in/apps/four-function/", svg: '4function' },
        // { label: "Scientific Calculator", value: "Scientific Calculator", link: "https://dld.srmist.edu.in/apps/scientific-calc/", svg: "scientific-calculator" },
        // { label: "Graphic Calculator", value: "Graphic Calculator", link: "https://dld.srmist.edu.in/apps/graphic-calc/index.html", svg: "graphic" },
        // { label: "Matrix Calculator", value: "Matrix Calculator", link: "https://dld.srmist.edu.in/apps/matrix-calc/", svg: "matrix" },
        // { label: "Geometry Calculator", value: "Geometry Calculator", link: "https://dld.srmist.edu.in/apps/geometry/", svg: "geometry" },
        // { label: "3D Calculator", value: "3D Calculator", link: "https://dld.srmist.edu.in/apps/3dcalculator/", svg: "3dcalc" },
        // { label: "ICT Intercative Board", value: "ICT Intercative Board", link: "https://dld.srmist.edu.in/apps/ictboard/", svg: "ictboard" },
        // { label: "HTML-JS Editor", value: "HTML-JS Editor", link: "https://dld.srmist.edu.in/apps/webeditor/", svg: "webeditor" },
        // { label: "WhiteBoard", value: "WhiteBoard", link: "https://dld.srmist.edu.in/apps/whiteboard/", svg: "whiteboard" },
        // { label: "Virtual WhiteBoard", value: "Virtual WhiteBoard", link: "https://dld.srmist.edu.in/apps/virtualwhiteboard/", svg: "vwhiteboard" },
        // { label: "Countdown Timer", value: "Countdown Timer", link: "https://dld.srmist.edu.in/apps/countdown/", svg: "countdown" },
        // { label: "Flowchart Tool", value: "Flowchart Tool", link: "https://dld.srmist.edu.in/apps/flowchart/", svg: "flowchart" },
        // { label: "Online LaTeX Editor", value: "Online LaTeX Editor", link: "https://dld.srmist.edu.in/apps/latex/", svg: "latex" },
        // { label: "LaTeX Equation Editor", value: "LaTeX Equation Editor", link: "https://dld.srmist.edu.in/apps/latexmath/", svg: "equation" },
        // { label: "ICT Circuit", value: "ICT Circuit", link: "https://dld.srmist.edu.in/apps/circuit/", svg: "circuit" },
        // { label: "CAD-3D", value: "CAD-3D", link: "https://dld.srmist.edu.in/apps/ictcad/", svg: "cad3d" },
        // { label: "CAD-2D", value: "CAD-2D", link: "https://dld.srmist.edu.in/apps/ictcad/sketcher.html#__sample2D__", svg: "cad2d" },
        // { label: "8 Bit Assembler", value: "8 Bit Assembler", link: "https://dld.srmist.edu.in/apps/assembler8bit/", svg: "assembler8bit" },
        // { label: "16 Bit Assembler", value: "16 Bit Assembler", link: "https://dld.srmist.edu.in/apps/assembler16bit/", svg: "assembler16bit" },
        // { label: "Automaton Simulator", value: "Automaton Simulator", link: "https://dld.srmist.edu.in/apps/automatonsimulator/", svg: "automaton" },
        // { label: "Mindmap Tool", value: "Mindmap Tool", link: "https://dld.srmist.edu.in/apps/mindmap/", svg: "mindmap" },
        // { label: "Online Spreadsheet", value: "Online Spreadsheet", link: "https://dld.srmist.edu.in/apps/spreadsheet/", svg: "excel" },
        // { label: "Crossword Maker", value: "Crossword Maker", link: "https://dld.srmist.edu.in/apps/crossword/", svg: "crossword" },
        // { label: "WordSearch Generator", value: "WordSearch Generator", link: "https://dld.srmist.edu.in/apps/word-search-generator/", svg: "wordsearch" },
        // { label: "Webcam Capture", value: "Webcam Capture", link: "https://dld.srmist.edu.in/apps/webcam/", svg: "webcam" },
        // { label: "Web PDF Editor", value: "Web PDF Editor", link: "https://dld.srmist.edu.in/apps/pdfeditor/", svg: "pdf" },
        // { label: "Dicom Viewer", value: "Dicom Viewer", link: "https://dld.srmist.edu.in/apps/u-dicom-viewer/", svg: "file" },
        // { label: "Arduino Simulator", value: "Arduino Simulator", link: "https://dld.srmist.edu.in/apps/netSwarm/", svg: "arduino" },
        // { label: "Structural Analyser", value: "Structural Analyzer", link: "https://dld.srmist.edu.in/apps/structural-analyser/", svg: "arduino" },
        // { label: "Network Simulator", value: "Structural Analyzer", link: "https://dld.srmist.edu.in/apps/networksimulator/public/index.html/", svg: "arduino" },
    ],
    "level1":
        ['Choose', 'Count', 'Cite', 'Define', 'Describe', 'Distinguish', 'Draw', 'Find', 'Group', 'Identify', 'Know', 'Label', 'List', 'Listen', 'Locate', 'Match', 'Memorize', 'Name', 'Outline', 'Quote', 'Read', 'Repeat', 'Recall', 'Recite', 'Relate', 'Review', 'Recognize', 'Record', 'Reproduce', 'Select', 'State', 'Sequence', 'Show', 'Sort', 'Tell', 'Underline', 'Write',],
    "level2":
        ['Account for', 'Annotate', 'Associate', 'Comprehend', 'Convert', 'Conclude', 'Define', 'Defend', 'Describe', 'Distinguish', 'Demonstrate', 'Discuss', 'Estimate', 'Explain', 'Extend', 'Generalize', 'Gives', 'examples', 'Infer', 'Interpret', 'Identify', 'Predict', 'Illustrate', 'Rewrite', 'Observe', 'Report', 'Outline', 'Restate', 'Paraphrase', 'Retell', 'Research', 'Review', 'Recognize', 'Reorganize', 'Summarize', 'Translate'],
    'level3':
        ['Apply', 'Adapt', 'Change', 'Collect', 'Choose', 'Compute', 'Calculate', 'Construct', 'Draw', 'Dramatize', 'Demonstrate', 'Exhibit', 'Interview', 'Illustrate', 'Interpret', 'Make', 'Manipulate', 'Operate', 'Prepare', 'Produce', 'Practice', 'Sequence', 'Role-play', 'Transfer', 'Select', 'Translate', 'Show', 'Use'],
    'level4':
        ['Appraise', 'Analyze', 'Arrange', 'Break', 'down', 'Characterize', 'Classify', 'Compare', 'Contrast', 'Calculate', 'Criticize', 'Debate', 'Deconstruct', 'Deduce', 'Detect', 'Diagram', 'Differentiate', 'Discriminate', 'Dissect', 'Distinguish', 'Draw', 'Examine', 'Infer', 'Experiment', 'Inquire', 'Group', 'Inspect', 'Identify', 'Investigate', 'Illustrate', 'Outline', 'Order', 'Probe', 'Question', 'Relate', 'Research', 'Select', 'Separate', 'Sequence', 'Survey', 'Test'],
    'level5':
        ['Appraise', 'Argue', 'Assess', 'Choose', 'Compare', 'Conclude', 'Contrast', 'Criticize', 'Critique', 'Debate', 'Decide', 'Deduce', 'Defend', 'Describe', 'Determine', 'Discriminate', 'Evaluate', 'Explain', 'Interpret', 'Infer', 'Justify', 'Prove', 'Judge', 'Probe', 'Measure', 'Rank', 'Predict', 'Rate', 'Prioritize', 'Relate', 'Revise', 'Recommend', 'Reject', 'Score', 'Summarize', 'Support', 'Select', 'Validate', 'Value'],
    'level6':
        ['Assemble', 'Act', 'Blend', 'Categorize', 'Combine', 'Compile', 'Compose', 'Construct', 'Create', 'Devise', 'Design', 'Develop', 'Explain', 'Forecast', 'Formulate', 'Generate', 'Integrate', 'Invent', 'Improve', 'Imagine', 'Modify', 'Plan', 'Make', 'Prepare', 'Organize', 'Produce', 'Originate', 'Propose', 'Perform', 'Predict', 'Rearrange', 'Reconstruct', 'Relate', 'Reorganize', 'Revise', 'Rewrite', 'Summarize', 'Set up', 'Tell', 'Write'],
    "bloomstaxonomy": ["", "Remember", "Understand", "Apply", "Analyze", "Evaluate", "Create"],
    "firstPageSlogans": [
        "Whatever you do, work at it with all your heart, as working for the Lord, not for human masters, since you know that you will receive an inheritance from the Lord as a reward. It is the Lord Christ you are serving - Colossians 3:23-24 (NIV)",
        "Commit to the Lord whatever you do, and He will establish your plans  - Proverbs 16:3 (NIV)​",
        "I can do all this through Him who gives me strength -  Philippians 4:13 (NIV)",
      
    ],
};

export default config;
//  keys: CreateCourses : "createnewcourse" && Full Import / Export : "johnblesswinC"
//  "curriculaserver": "http://127.0.0.1:5000",
//  "curriculaserver": "http://172.1.34.102:5002",
//  "curriculaserver": "https://dld.srmist.edu.in/curricula/server",
//  "curriculaserver": "https://care.srmist.edu.in/dmcurricula/server",
//  Array.from({length: 5}, (v, k) => console.log(k+1)); 