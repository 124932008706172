import React, { Component } from 'react'
import { Select, Typography, Row, Card, Statistic, Col, Space, Descriptions, Button, Tag, Upload, message, Spin, Result, Modal } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import Iframe from 'react-iframe'
const { Option } = Select;
const { Text } = Typography;
const { Meta } = Card;
const page_name = "assessment";

class LPispring extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        loading: true,
        UNIT: 1,
        SESSION: 0,
        SESSION_COUNT: 0,
        folderid: "",
        filename: "",
        fileStatus: [],
        error: 0, isModalOpen: false, selectedSession: 0, displayLabel: ""
    }

    componentDidMount() {
        this.getCourse(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourse(props.COURSE_CODE);
    }

    getCourse = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else
                        this.setState({ sessions: record, UNIT: 1, SESSION_COUNT: record[0].SESSIONCOUNT, error: 0 });
                }
            });

        this.handleFileStatus(COURSE_CODE);
    }

    handleFileStatus = (COURSE_CODE) => {
        let folderlink = "data/coordinator/" + COURSE_CODE + "/" + page_name;
        let sendData = {
            server: config.curriculaserver,
            path: folderlink,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/file/getallfilenames', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ fileStatus: res.data.result });
                }
                else
                    this.setState({ fileStatus: [] });
            });
        this.setState({ loading: false });
    }

    checkFileStatus = (sessionID) => {
        sessionID = parseInt(sessionID);
        if (this.state.fileStatus.includes(sessionID))
            return <Tag color="green" style={{ width: "74px" }}>Available</Tag>
        else
            return <Tag style={{ padding: "0px 20px" }} color="red">Empty</Tag>
    }


    handleChangeSelect = (label, value) => {
        this.setState({ [label]: parseInt(value) });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT });
        }
    }

    setSession = (SESSION, SLO) => {
        let filename = (parseInt(this.state.UNIT) * 100 + parseInt(SESSION)) + "" + SLO;
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/" + page_name;
        this.setState({ SESSION: SESSION, folderid: folderlink, filename: filename });
    }

    setButtonLink = (SESSION, SLO) => {
        let filename = (parseInt(this.state.UNIT) * 100 + parseInt(SESSION)) + "" + SLO + ".zip";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/" + page_name;

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    setButtonView = (SESSION, SLO) => {
        let filename = (parseInt(this.state.UNIT) * 100 + parseInt(SESSION)) + "" + SLO;
        if (parseInt(this.state.UNIT) && parseInt(SESSION) && SLO && this.state.COURSE_CODE) {
            this.setState({ displayLabel: "Unit " + this.state.UNIT + " Session " + SESSION + " SLO " + SLO, selectedSession: filename, isModalOpen: true });
        }
    }

    StatisticTemplate(title, value, suffix) {
        return (
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Card size="small" style={{ backgroundColor: "#ecf0f1" }}>
                    <img src={require('../../../images/' + suffix + '.svg').default} alt="" className="verifiable-skill-card-logo" />
                    <Statistic
                        title={title}
                        value={value}
                        valueStyle={{ color: '#3f8600' }}
                    />
                </Card>
            </Col>);
    }

    handleCancel = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        let { UNIT, isModalOpen, COURSE_CODE, selectedSession } = this.state;
        const params = {
            key: config.key,
            path: this.state.folderid,
            filename: this.state.filename + ".zip",
            session: this.state.filename,
        }

        let props = {
            accept: ".zip",
            name: 'file',
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/ispring/zipupload',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus };
                    });
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                const ZIP = file.type === 'application/zip' || file.type === 'application/octet-stream' || file.type === 'application/x-zip-compressed' || file.type === 'multipart/x-zip';
                if (!ZIP) {
                    message.error('You can only upload .zip file!');
                }
                const isLt3M = file.size / 1024 / 1024 < 3;
                if (!isLt3M) {
                    message.error('File must smaller than 3MB!');
                }
                return ZIP && isLt3M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <div className="padding-2">
                {this.state.error === 0 ?
                    (
                        <Spin spinning={this.state.loading}>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} lg={20}>
                                    <Text className="justify">
                                        An assessment tool, worksheets can be used by teachers to understand students’ previous knowledge, outcome of learning, and the process of learning; at the same time, they can be used to enable students to monitor the progress of their own learning. Use iSpring Software v9.3.3 or Later Versions. Zip file contains the following elements,
                                    </Text>

                                </Col>
                                <Col xs={24} lg={4}>
                                    <Card
                                        hoverable
                                        bordered={false}
                                        style={{
                                            width: "90%", boxShadow: "none"
                                        }}
                                        cover={<img alt="TEL" height="60" src={require('../../../images/zip.svg').default} />}
                                    >
                                        <Meta style={{ textAlign: "center" }} description={"file.zip < 3 MB"} />
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={16} className="mt-2">
                                {this.StatisticTemplate("Folder", "data", "folder")}
                                {this.StatisticTemplate(".html", "index.html", "html")}
                            </Row>

                            <Descriptions
                                bordered
                                column={{ lg: 1, md: 1, sm: 1, xs: 1 }}
                                className="mb-1 mt-3"
                                size="small"
                            >
                                <Descriptions.Item label="Choose Unit">
                                    <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                        onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                column={{ lg: 1, md: 1, sm: 1, xs: 1 }}
                                className="mb-1 mt-1"
                                size="small"
                            >
                                {
                                    Array.from({ length: this.state.SESSION_COUNT }, (v, SESSION) => {
                                        return (
                                            <Descriptions.Item label={"Session " + (SESSION + 1)} key={(SESSION + 1)}>
                                                <Row gutter={[8, 8]}>
                                                    <Col xs={24} lg={12}>
                                                        <Space>
                                                            <Text keyboard>SLO 1</Text>
                                                            <Upload {...props} >
                                                                <Button size="small" onClick={this.setSession.bind(this, (SESSION + 1), 1)}>
                                                                    <UploadOutlined /> Click to Upload
                                                                </Button>
                                                            </Upload>
                                                            {this.checkFileStatus((UNIT * 100) + (SESSION + 1) + "" + 1 + "")}
                                                            <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (SESSION + 1), 1)}>DOWNLOAD</Button>
                                                            <Button danger type="dashed" icon={<EyeOutlined />} size={"small"} onClick={this.setButtonView.bind(this, (SESSION + 1), 1)}>VIEW</Button>
                                                        </Space>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <Space>
                                                            <Text keyboard>SLO 2</Text>
                                                            <Upload {...props}>
                                                                <Button size="small" onClick={this.setSession.bind(this, (SESSION + 1), 2)}>
                                                                    <UploadOutlined /> Click to Upload
                                                                </Button>
                                                            </Upload>
                                                            {this.checkFileStatus((UNIT * 100) + (SESSION + 1) + "" + 2 + "")}
                                                            <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (SESSION + 1), 2)}>DOWNLOAD</Button>
                                                            <Button danger type="dashed" icon={<EyeOutlined />} size={"small"} onClick={this.setButtonView.bind(this, (SESSION + 1), 2)}>VIEW</Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>
                                        )
                                    })
                                }
                            </Descriptions>
                        </Spin>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }

                <Modal title={"iSpring Assessment - " + this.state.displayLabel} destroyOnClose footer={null} visible={isModalOpen} onCancel={this.handleCancel} width={630}>
                    <Iframe is="x-frame-bypass" url={config.curriculaserver + "/uploads/data/coordinator/" + COURSE_CODE + "/assessment/" + selectedSession + "/index.html"}
                        sandbox="allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
                        width="600px"
                        height="600px"
                        display="initial"
                        position="relative"
                        frameBorder="0" scrolling="no"
                        styles={{ backgroundColor: "red" }}
                        allowFullScreen />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LPispring));